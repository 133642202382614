import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

    // -- site  -------------------------------------------------------------------------------------------------
    /*
    // トップページ: /users/sign_in
    {
        path: '/',
        name: 'Home',
        meta: {layout: 'default'},
        component: () => import('../views/Home.vue')
    },
    */

    // -- user session -------------------------------------------------------------------------------------------------
    // 会員ログイン: /users/sign_in
    {
        path: '/users/sign_in',
        name: 'UsersSignIn',
        meta: {
            layout: 'login',
            title: '会員ログイン',
        },
        component: () => import('../views/users/session/SignIn.vue')
    },
    {
        path: '/customer/account/login',
        name: 'UsersSignIn',
        meta: {
            layout: 'login',
            title: '会員ログイン',
        },
        component: () => import('../views/users/session/SignIn.vue')
    },

    // -- user session -------------------------------------------------------------------------------------------------
    // 会員ログアウト（強制的デバッグ用）: /users/sign_out
    {
        path: '/users/sign_out',
        name: 'UsersSignOut',
        meta: {
            layout: 'login',
            title: '会員ログアウト',
            // description: '会員ログイン'
        },
        component: () => import('../views/users/session/SignOut.vue')
    },

    // -- user password ------------------------------------------------------------------------------------------------
    // パスワードを忘れたら: /users/password/new
    {
        path: '/users/password/new',
        name: 'UsersNewPassword',
        meta: {
            layout: 'login',
            title: 'パスワードを忘れたら',
        },
        component: () => import('../views/users/password/New.vue')
    },
    {
        path: '/customer/account/password/new',
        name: 'UsersNewPassword',
        meta: {
            layout: 'login',
            title: 'パスワードを忘れたら',
        },
        component: () => import('../views/users/password/New.vue')
    },
    // パスワードを忘れたら メール送信完了: /users/password/new/finish
    {
        path: '/users/password/new/finish',
        name: 'UsersNewPasswordFinish',
        meta: {
            layout: 'login',
            title: 'パスワードを忘れたら',
            // description: 'パスワードを忘れたら'
        },
        component: () => import('../views/users/password/NewFinish.vue')
    },
    // パスワードを忘れたら 編集： /users/password/edit/:reset_password_token
    {
        path: '/users/password/edit/:reset_password_token',
        name: 'UsersPasswordEdit',
        meta: {
            layout: 'login',
            title: 'パスワードの再設定',
            // description: 'パスワードの再設定'
        },
        component: () => import('../views/users/password/Edit.vue')
    },
    // パスワードを忘れたら 編集 完了： /users/password/edit/finish
    {
        path: '/users/password/edit/finish',
        name: 'UsersPasswordEditFinish',
        meta: {
            layout: 'login',
            title: 'パスワードの再設定',
            // description: 'パスワードの再設定'
        },
        component: () => import('../views/users/password/EditFinish.vue')
    },

    // -- user registration --------------------------------------------------------------------------------------------
    // 新規会員登録： /users/registration/
    {
        path: '/users/registration/',
        name: 'UsersRegistration',
        meta: {
            layout: 'registration',
            title: '新規会員登録',
            // description: '新規会員登録'
        },
        component: () => import('../views/users/registration/Rank.vue')
    },

    // 新規会員登録： /customer/account/create/
    {
        path: '/customer/account/create/',
        name: 'UsersRegistration2',
        meta: {
            layout: 'registration',
            title: '新規会員登録',
            // description: '新規会員登録'
        },
        component: () => import('../views/users/registration/Rank.vue')
    },

    // 新規会員登録（b2bアフィリエイト）： /customer/account/create-partner/:campaign_code
    {
        path: '/customer/account/create-partner/:campaign_code',
        name: 'UsersRegistrationPartner',
        meta: {
            layout: 'registration',
            title: '新規会員登録',
            // description: '新規会員登録'
        },
        component: () => import('../views/users/registration/Rank.vue')
    },

    // 新規会員登録 メール設定： /users/registration/new
    {
        path: '/users/registration/new',
        name: 'UsersNewRegistration',
        meta: {
            layout: 'registration',
            title: '新規会員登録',
            // description: '新規会員登録'
        },
        component: () => import('../views/users/registration/New.vue')
    },

    // 新規会員登録 メール設定（b2bアフィリエイト）： /users/registration/new/:campaign_code
    {
        path: '/users/registration/new/:campaign_code',
        name: 'UsersNewRegistrationPartner',
        meta: {
            layout: 'registration',
            title: '新規会員登録',
            // description: '新規会員登録'
        },
        component: () => import('../views/users/registration/New.vue')
    },

    // -- user confirmation --------------------------------------------------------------------------------------------
    // 新規会員登録 塾生情報の登録： /users/confirmation/page1/
    {
        path: '/users/confirmation/page1/',
        name: 'UsersConfirmationPage1',
        meta: {
            layout: 'confirmation',
            title: '塾生情報の登録',
            // description: '塾生情報の登録'
        },
        component: () => import('../views/users/confirmation/Page1.vue')
    },
    // 新規会員登録 ご住所の登録： /users/confirmation/page2/
    {
        path: '/users/confirmation/page2/',
        name: 'UsersConfirmationPage2',
        meta: {
            layout: 'confirmation',
            title: 'ご住所の登録',
            // description: 'ご住所の登録'
        },
        component: () => import('../views/users/confirmation/Page2.vue')
    },
    // 新規会員登録 パスワードの設定： /users/confirmation/page3/
    {
        path: '/users/confirmation/page3/',
        name: 'UsersConfirmationPage3',
        meta: {
            layout: 'confirmation',
            title: 'パスワードの設定',
            // description: 'パスワードの設定'
        },
        component: () => import('../views/users/confirmation/Page3.vue')
    },
    // 新規会員登録 登録情報の確認： /users/confirmation/confirm/
    {
        path: '/users/confirmation/confirm/',
        name: 'UsersConfirmationConfirm',
        meta: {
            layout: 'confirmation',
            title: '入力内容の確認',
            // description: '登録情報の確認'
        },
        component: () => import('../views/users/confirmation/Confirm.vue')
    },
    // 新規会員登録 登録完了： /users/confirmation/finish/:token
    {
        path: '/users/confirmation/finish/:token',
        name: 'UsersConfirmationFinish',
        meta: {
            layout: 'confirmation',
            title: '入塾金の支払いへ進む',
            // description: '登録完了'
        },
        component: () => import('../views/users/confirmation/Finish.vue')
    },
    // 新規会員登録 お支払い完了： /users/confirmation/payment/finish/:one_time_token
    {
        path: '/users/confirmation/payment/finish/:one_time_token/:rank',
        name: 'UsersConfirmationPaymentFinish',
        meta: {
            layout: 'confirmation',
            title: 'お支払い完了',
            // description: 'お支払い完了'
        },
        component: () => import('../views/users/confirmation/PaymentFinish.vue')
    },


    // -- student user registration --------------------------------------------------------------------------------------------
    // 新規会員登録： /users/registration/student
    {
        path: '/users/registration/student/',
        name: 'UsersRegistrationStudent',
        meta: {
            layout: 'registration',
            title: '新規会員登録',
            // description: '新規会員登録'
        },
        component: () => import('../views/users/confirmation/student/New.vue')
    },

    // 新規会員登録 メール設定（b2bアフィリエイト）： /users/registration/student/:campaign_code
    {
        path: '/users/registration/student/:campaign_code',
        name: 'UsersNewRegistrationStudentPartner',
        meta: {
            layout: 'registration',
            title: '新規会員登録',
            // description: '新規会員登録'
        },
        component: () => import('../views/users/confirmation/student/New.vue')
    },

    // -- user confirmation --------------------------------------------------------------------------------------------
    // 新規会員登録 塾生情報の登録： /users/confirmation/student/page1/
    {
        path: '/users/confirmation/student/page1/',
        name: 'UsersConfirmationStudentPage1',
        meta: {
            layout: 'confirmation',
            title: '塾生情報の登録',
            // description: '塾生情報の登録'
        },
        component: () => import('../views/users/confirmation/student/Page1.vue')
    },
    // 新規会員登録 ご住所の登録： /users/confirmation/student/page2/
    {
        path: '/users/confirmation/student/page2/',
        name: 'UsersConfirmationStudentPage2',
        meta: {
            layout: 'confirmation',
            title: 'ご住所の登録',
            // description: 'ご住所の登録'
        },
        component: () => import('../views/users/confirmation/student/Page2.vue')
    },
    // 新規会員登録 パスワードの設定： /users/confirmation/student/page3/
    {
        path: '/users/confirmation/student/page3/',
        name: 'UsersConfirmationStudentPage3',
        meta: {
            layout: 'confirmation',
            title: 'パスワードの設定',
            // description: 'パスワードの設定'
        },
        component: () => import('../views/users/confirmation/student/Page3.vue')
    },
    // 新規会員登録 登録情報の確認： /users/confirmation/student/confirm/
    {
        path: '/users/confirmation/student/confirm/',
        name: 'UsersConfirmationStudentConfirm',
        meta: {
            layout: 'confirmation',
            title: '入力内容の確認',
            // description: '登録情報の確認'
        },
        component: () => import('../views/users/confirmation/student/Confirm.vue')
    },
    // 新規会員登録 登録完了： /users/confirmation/student/finish/:token
    {
        path: '/users/confirmation/student/finish/:token',
        name: 'UsersConfirmationStudentFinish',
        meta: {
            layout: 'confirmation',
            title: '入塾金の支払いへ進む',
            // description: '登録完了'
        },
        component: () => import('../views/users/confirmation/student/Finish.vue')
    },
    // 新規会員登録 お支払い完了： /users/confirmation/student/payment/finish/:one_time_token
    {
        path: '/users/confirmation/student/payment/finish/:one_time_token/:rank',
        name: 'UsersConfirmationStudentPaymentFinish',
        meta: {
            layout: 'confirmation',
            title: 'お支払い完了',
            // description: 'お支払い完了'
        },
        component: () => import('../views/users/confirmation/student/PaymentFinish.vue')
    },

    // 会員 TOP： /customer/
    {
        path: '/customer/',
        name: 'UsersCustomerTop',
        meta: {
            layout: 'default',
            title: '塾生TOP',
            // description: '塾生TOP',
            authenticate_user_join: true
        },
        component: () => import('../views/users/customer/Top.vue')
    },

    // マイページ： /customer/account/
    {
        path: '/customer/account/',
        name: 'UsersCustomerAccount',
        meta: {
            layout: 'default',
            title: 'マイページ',
            // description: 'マイページ',
            authenticate_user: true,
            authenticate_user_sleep: true
        },
        component: () => import('../views/users/customer/Account.vue')
    },

    // マイページ（月謝未確認）： /customer/empty_paid_join/
    {
        path: '/customer/empty_paid_join',
        name: 'UsersCustomerEmptyPaidJoin',
        meta: {
            layout: 'default',
            title: '入塾金決済の確認中',
            // description: '入塾金決済の確認中',
            authenticate_user: true
        },
        component: () => import('../views/users/customer/EmptyPaidJoin.vue')
    },

    // 塾生情報の編集： /customer/account/profile/edit
    {
        path: '/customer/account/profile/edit',
        name: 'UsersCustomerProfileEdit',
        meta: {
            layout: 'default',
            title: '塾生情報の編集',
            // description: '塾生情報の編集',
            authenticate_user: true,
            authenticate_user_sleep: true
        },
        component: () => import('../views/users/customer/ProfileEdit.vue')
    },

    // プロフィールの編集： /customer/account/edit
    {
        path: '/customer/account/edit',
        name: 'UsersCustomerAccountEdit',
        meta: {
            layout: 'default',
            title: 'プロフィールの編集',
            // description: 'プロフィールの編集',
            authenticate_user: true,
            authenticate_user_sleep: true
        },
        component: () => import('../views/users/customer/AccountEdit.vue')
    },

    // お支払い情報の更新： /customer/card_update/finish
    {
        path: '/customer/card_update/finish',
        name: 'UsersCustomerCardUpdateFinish',
        meta: {
            layout: 'default',
            title: 'お支払い情報の更新',
            // description: 'お支払い情報の更新',
            authenticate_user: true,
            authenticate_user_sleep: true
        },
        component: () => import('../views/users/customer/CardUpdateFinish.vue')
    },

    // ポイント通帳： /customer/point
    {
        path: '/customer/point',
        name: 'UsersCustomerPoint',
        meta: {
            layout: 'default',
            title: 'ポイント通帳',
            authenticate_user: true,
            authenticate_user_sleep: true
        },
        component: () => import('../views/users/customer/Point.vue')
    },

    // 各種設定と活動状況： /customer/sns_dashboard
    {
        path: '/customer/sns_dashboard',
        name: 'UsersCustomerSnsDashboard',
        meta: {
            layout: 'default',
            title: '各種設定と活動状況',
            authenticate_user_join: true
        },
        component: () => import('../views/users/customer/SnsDashboard.vue')
    },

    // 塾生クラスの変更・休塾・退塾： /customer/rank
    {
        path: '/customer/rank',
        name: 'UsersCustomerRank',
        meta: {
            layout: 'default',
            title: '塾生クラスの変更',
            // description: 'お支払い情報の更新',
            authenticate_user_join: true
        },
        component: () => import('../views/users/customer/Rank.vue')
    },

    // 塾生クラスの変更： /customer/rank/change
    {
        path: '/customer/rank/change',
        name: 'UsersCustomerRankChange',
        meta: {
            layout: 'default',
            title: '塾生クラスの変更',
            authenticate_user_join: true
        },
        component: () => import('../views/users/customer/RankChange.vue')
    },

    // 塾生クラスの変更・旧塾生専用： /customer/rank/change/default
    {
        path: '/customer/rank/change/default',
        name: 'UsersCustomerRankChangeDefault',
        meta: {
            layout: 'default',
            title: '塾生クラスの変更',
            authenticate_user_join: true
        },
        component: () => import('../views/users/customer/RankChangeDefault.vue')
    },

    // 塾生クラスの変更・変更選択： /customer/rank/change/:new_rank
    {
        path: '/customer/rank/change/:new_rank(preparatory|regular|regular_plus)',
        name: 'UsersCustomerRankChangeNew',
        meta: {
            layout: 'default',
            title: '塾生クラスの変更',
            authenticate_user_join: true
        },
        component: () => import('../views/users/customer/RankChangeNew.vue')
    },

    // 塾生クラスの変更申請・確認： /customer/rank/change/confirm
    {
        path: '/customer/rank/change/confirm',
        name: 'UsersCustomerRankChangeConfirm',
        meta: {
            layout: 'default',
            title: '塾生クラスの変更',
            authenticate_user_join: true
        },
        component: () => import('../views/users/customer/RankChangeConfirm.vue')
    },

    // 塾生クラスの変更申請・完了： /customer/rank/change/finish
    {
        path: '/customer/rank/change/finish',
        name: 'UsersCustomerRankChangeFinish',
        meta: {
            layout: 'default',
            title: '塾生クラスの変更',
            authenticate_user_join: true
        },
        component: () => import('../views/users/customer/RankChangeFinish.vue')
    },

    // 塾生クラスの変更申請キャンセル・完了： /customer/rank/change/cancel/finish
    {
        path: '/customer/rank/change/cancel/finish',
        name: 'UsersCustomerRankChangeCancelFinish',
        meta: {
            layout: 'default',
            title: '塾生クラスの変更申請キャンセル',
            authenticate_user_join: true
        },
        component: () => import('../views/users/customer/RankChangeCancelFinish.vue')
    },

    // 塾生クラスの変更申請キャンセル： /customer/rank/change/cancel
    {
        path: '/customer/rank/change/cancel',
        name: 'UsersCustomerRankChangeCancel',
        meta: {
            layout: 'default',
            title: '塾生クラスの変更申請キャンセル',
            authenticate_user_join: true
        },
        component: () => import('../views/users/customer/RankChangeCancel.vue')
    },

    // 退塾申請： /customer/withdrawal
    {
        path: '/customer/withdrawal',
        name: 'UsersCustomerWithdrawal',
        meta: {
            layout: 'default',
            title: '複眼経済塾の退塾',
            authenticate_user: true,
            authenticate_user_sleep: true
        },
        component: () => import('../views/users/customer/Withdrawal.vue')
    },

    // 休塾キャンセル完了： /customer/sleep/cancel/finish
    {
        path: '/customer/sleep/cancel/finish',
        name: 'UsersCustomerSleepCancelFinish',
        meta: {
            layout: 'default',
            title: '複眼経済塾の利用を再開',
            authenticate_user: true,
            authenticate_user_sleep: true,
        },
        component: () => import('../views/users/customer/SleepCancelFinish.vue')
    },

    // 入塾前：塾生クラスの変更： /customer/no_join/rank/change
    {
        path: '/customer/no_join/rank/change',
        name: 'UsersCustomerNoJoinRankChange',
        meta: {
            layout: 'default',
            title: '塾生クラスの変更',
            authenticate_user_no_join: true
        },
        component: () => import('../views/users/customer/no_join/RankChange.vue')
    },

    // 入塾前：塾生クラスの変更・変更選択： /customer/no_join/rank/change/:new_rank
    {
        path: '/customer/no_join/rank/change/:new_rank(preparatory|regular|regular_plus)',
        name: 'UsersCustomerNoJoinRankChangeNew',
        meta: {
            layout: 'default',
            title: '塾生クラスの変更',
            authenticate_user_no_join: true
        },
        component: () => import('../views/users/customer/no_join/RankChangeNew.vue')
    },

    // 入塾前：塾生クラスの変更申請・確認： /customer/no_join/rank/change/confirm
    {
        path: '/customer/no_join/rank/change/confirm',
        name: 'UsersCustomerNoJoinRankChangeConfirm',
        meta: {
            layout: 'default',
            title: '塾生クラスの変更',
            authenticate_user_no_join: true
        },
        component: () => import('../views/users/customer/no_join/RankChangeConfirm.vue')
    },

    // 入塾前：塾生クラスの変更申請・完了： /customer/no_join/rank/change/finish
    {
        path: '/customer/no_join/rank/change/finish',
        name: 'UsersCustomerNoJoinRankChangeFinish',
        meta: {
            layout: 'default',
            title: '塾生クラスの変更',
            authenticate_user_no_join: true
        },
        component: () => import('../views/users/customer/no_join/RankChangeFinish.vue')
    },

    // 参加イベント： /customer/join_events
    {
        path: '/customer/join_events',
        name: 'UsersCustomerJoinEvents',
        meta: {
            layout: 'default',
            title: '研修会参加履歴',
            authenticate_user: true
        },
        component: () => import('../views/users/customer/JoinEvents.vue')
    },

    // 認定資格： /customer/join_events
    {
        path: '/customer/achievement',
        name: 'UsersCustomerAchievement',
        meta: {
            layout: 'default',
            title: '複眼経済塾認定資格',
            authenticate_user: true
        },
        component: () => import('../views/users/customer/Achievement.vue')
    },

    // オンラインショップ・購入履歴： /customer/shopping/histories
    {
        path: '/customer/shopping/histories',
        name: 'UsersCustomerShoppingHistories',
        meta: {
            layout: 'default',
            title: 'オンラインショップ購入履歴',
            authenticate_user: true
        },
        component: () => import('../views/users/customer/shop/Histories.vue')
    },

    // オンラインショップ・商品一覧： /shopping/articles
    {
        path: '/shopping/articles',
        name: 'ShoppingArticles',
        meta: {
            layout: 'default',
            title: 'オンラインショップ商品一覧',
            // authenticate_user: true
        },
        component: () => import('../views/shop/Articles.vue')
    },

    // オンラインショップ・商品詳細： /article/:id
    {
        path: '/shopping/articles/:id',
        name: 'ShoppingArticle',
        meta: {
            layout: 'default',
            title: 'オンラインショップ商品詳細',
            // authenticate_user: true
        },
        component: () => import('../views/shop/Article.vue')
    },

    // オンラインショップ・カート： /shopping/cart
    {
        path: '/shopping/cart',
        name: 'ShoppingCart',
        meta: {
            layout: 'default',
            title: 'オンラインショップ・カート',
            // authenticate_user: true
        },
        component: () => import('../views/shop/Cart.vue')
    },

    // オンラインショップ・お届け先の入力： /shopping/register
    {
        path: '/shopping/register',
        name: 'ShoppingRegister',
        meta: {
            layout: 'default',
            title: 'オンラインショップ・お届け先の入力',
            // authenticate_user: true
        },
        component: () => import('../views/shop/Register.vue')
    },

    // オンラインショップ・お届け先の入力： /shopping/register/non_member
    {
        path: '/shopping/register/non_member',
        name: 'ShoppingRegisterNonMember',
        meta: {
            layout: 'default',
            title: 'オンラインショップ・お届け先の入力',
            // authenticate_user: true
        },
        component: () => import('../views/shop/RegisterNonMember.vue')
    },

    // オンラインショップ・0510： /shopping/payment
    {
        path: '/shopping/payment',
        name: 'ShoppingPayment',
        meta: {
            layout: 'default',
            title: 'オンラインショップ・0510',
            // authenticate_user: true
        },
        component: () => import('../views/shop/Payment.vue')
    },

    // オンラインショップ・ご注文を受け付けました： /shopping/thanks
    {
        path: '/shopping/thanks/:id',
        name: 'ShoppingThanks',
        meta: {
            layout: 'default',
            title: 'オンラインショップ・ご注文を受け付けました',
            // authenticate_user: true
        },
        component: () => import('../views/shop/Thanks.vue')
    },

    // SNSスペース・ルーム： /sns/space/
    {
        path: '/sns/space/:nav_category/:space_id-:room_id/',
        name: 'SnsSpace',
        meta: {
            layout: 'default',
            title: 'SNSスペース',
            // authenticate_user: true
        },
        component: () => import('../views/sns/space/Index.vue')
    },

    // SNSタイムライン： /sns/timeline/
    {
        path: '/sns/timeline/:user_id/:command?',
        name: 'SnsTimeline',
        meta: {
            layout: 'default',
            title: 'SNSタイムライン',
            // description: 'SNSタイムライン',
            authenticate_user_join: true
        },
        component: () => import('../views/sns/timeline/Index.vue')
    },

    // 自分のSNSタイムライン： /sns/my_timeline/
    {
        path: '/sns/my_timeline',
        name: 'SnsMyTimeline',
        meta: {
            layout: 'default',
            title: 'SNSタイムライン',
            // description: 'SNSタイムライン',
            authenticate_user_join: true
        },
        component: () => import('../views/sns/timeline/My.vue')
    },

    // SNS投稿パーマリンク： /sns/post/
    {
        path: '/sns/post/:post_id/',
        name: 'SnsPost',
        meta: {
            layout: 'default',
            title: 'SNS投稿',
            // description: 'SNS投稿',
            // authenticate_user_join: true
        },
        component: () => import('../views/sns/post/Index.vue')
    },

    // SNSおすすめコンテンツ： /sns/space/content/recommends
    {
        path: '/sns/space/content/recommends',
        name: 'SnsPostRecommends',
        meta: {
            layout: 'default',
            title: 'おすすめ講義',
            // description: 'おすすめ講義',
            authenticate_user_join: true
        },
        component: () => import('../views/sns/recommends/Index.vue')
    },

    // SNSテーマ別グループ： /sns/socials
    {
        path: '/sns/socials',
        name: 'SnsSocials',
        meta: {
            layout: 'default',
            title: 'SNSテーマ別グループ一覧',
            // description: 'SNSテーマ別グループ一覧',
            authenticate_user_join: true
        },
        component: () => import('../views/sns/socials/Index.vue')
    },

    // イベント専用SNS一覧： /sns/workshops
    {
        path: '/sns/workshops',
        name: 'SnsWorkshops',
        meta: {
            layout: 'default',
            title: '研修会専用SNS一覧',
            authenticate_user_join: true
        },
        component: () => import('../views/sns/workshops/Index.vue')
    },

    // SNS通知： /sns/alerts
    {
        path: '/sns/alerts',
        name: 'SnsAlerts',
        meta: {
            layout: 'default',
            title: 'SNSからの通知',
            // description: 'SNSからの通知',
            authenticate_user_join: true
        },
        component: () => import('../views/sns/alerts/Index.vue')
    },

    // SNS検索： /sns/search
    {
        path: '/sns/Search',
        name: 'SnsSearch',
        meta: {
            layout: 'default',
            title: '検索',
            // description: 'SNSからの通知',
            authenticate_user_join: true
        },
        component: () => import('../views/sns/search/Index.vue')
    },

    // SNSお気に入り： /sns/favorites
    {
        path: '/sns/favorites',
        name: 'SnsFavorites',
        meta: {
            layout: 'default',
            title: 'お気に入りの投稿',
            // description: 'お気に入りの投稿',
            authenticate_user_join: true
        },
        component: () => import('../views/sns/favorites/Index.vue')
    },

    // 500： /500
    {
        path: '/500',
        name: '500',
        meta: {
            layout: 'default',
            title: 'Internal Server Error',
            // description: '',
        },
        component: () => import('../views/500.vue')
    },

    // 404： /404
    {
        path: '/404',
        name: '404',
        meta: {
            layout: 'default',
            title: '404 not found',
            // description: '',
        },
        component: () => import('../views/404.vue')
    },

    // 403： /403
    {
        path: '/403',
        name: '403',
        meta: {
            layout: 'default',
            title: 'Forbidden',
            // description: '',
        },
        component: () => import('../views/403.vue')
    },

    // お問い合わせ： /contacts/
    {
        path: '/contacts/',
        name: 'Contacts',
        meta: {
            layout: 'default',
            title: 'お問い合わせ',
            // description: 'お問い合わせ',
        },
        component: () => import('../views/users/contacts/New.vue')
    },

    // お問い合わせ： /contacts/finish
    {
        path: '/contacts/finish',
        name: 'ContactsFinish',
        meta: {
            layout: 'default',
            title: 'お問い合わせ',
            // description: 'お問い合わせ',
        },
        component: () => import('../views/users/contacts/Finish.vue')
    },

    // FAQ： /faq
    {
        path: '/faq',
        name: 'faq',
        meta: {
            layout: 'default',
            title: 'よくある質問・ヘルプ',
            // description: 'よくある質問・ヘルプ',
        },
        component: () => import('../views/site/Faq.vue')
    },

    // LP： /lp
    {
        path: '/lp',
        name: 'lp',
        meta: {
            layout: 'lp',
            title: '複眼経済塾とは',
            // description: '複眼経済塾とは',
        },
        component: () => import('../views/site/Lp.vue')
    },

    // LP： /lp_registrable
    {
        path: '/lp_registrable',
        name: 'lp',
        meta: {
            layout: 'lp',
            title: '複眼経済塾とは',
            // description: '複眼経済塾とは',
        },
        component: () => import('../views/site/Lp.vue')
    },

    // LP： /lp_registrable
    {
        path: '/lp_standby',
        name: 'lp',
        meta: {
            layout: 'lp',
            title: '複眼経済塾とは',
            // description: '複眼経済塾とは',
        },
        component: () => import('../views/site/Lp.vue')
    },

    // 学生科 LP： /st/lp
    {
        path: '/st/lp',
        name: 'StLp',
        meta: {
            layout: 'lp',
            title: '複眼経済塾とは',
            // description: '複眼経済塾とは',
        },
        component: () => import('../views/site/Lp.vue')
    },

    // maintenance： /maintenance
    {
        path: '/maintenance',
        name: 'Maintenance',
        meta: {
            layout: 'maintenance',
            title: 'メンテナンス',
            // description: '複眼経済塾とは',
        },
        component: () => import('../views/Static.vue')
    },

    // mainte： /mainte
    {
        path: '/mainte',
        name: 'Mainte',
        meta: {
            layout: 'maintenance',
            title: 'メンテナンス',
        },
        component: () => import('../views/Mainte.vue')
    },

    //  管理者： /admin
    {
        path: '/admin',
        name: 'Admin',
        meta: {
            layout: 'default',
            title: '管理者ページ',
            // description: '管理者ページ',
            authenticate_admin_user: true
        },
        component: () => import('../views/users/admin/Index.vue')
    },

    // 月例会一覧
    {
        path: '/getsurei',
        name: 'GetsureiList',
        meta: {
            layout: 'default',
            title: '月例会スケジュール',
            // description: '管理者ページ',
            authenticate_user: true
        },
        component: () => import('../views/site/getsurei/Index.vue')
    },

    // 月例会詳細
    {
        path: '/getsurei/page/:id',
        name: 'GetsureiShow',
        meta: {
            layout: 'default',
            title: '月例会詳細',
            authenticate_user: true
        },
        component: () => import('../views/site/getsurei/Show.vue')
    },

    // 月例会 オンライン中継
    {
        path: '/getsurei/broadcast/:id',
        name: 'GetsureiBroadcast',
        meta: {
            layout: 'default',
            title: '月例会オンライン中継',
            authenticate_user: true
        },
        component: () => import('../views/site/getsurei/Broadcast.vue')
    },

    // 月例会申し込み・フォーム
    {
        path: '/getsurei/form/:id',
        name: 'GetsureiForm',
        meta: {
            layout: 'default',
            title: '月例会申し込み',
            authenticate_user: true
        },
        component: () => import('../views/site/getsurei/Form.vue')
    },

    // 月例会申し込み・確認
    {
        path: '/getsurei/confirm/:id',
        name: 'GetsureiConfirm',
        meta: {
            layout: 'default',
            title: '月例会申し込み確認',
            authenticate_user: true
        },
        component: () => import('../views/site/getsurei/Confirm.vue')
    },

    // 月例会申し込み・終了
    {
        path: '/getsurei/thanks/:id',
        name: 'GetsureiFinish',
        meta: {
            layout: 'default',
            title: '月例会申し込み完了',
            authenticate_user: true
        },
        component: () => import('../views/site/getsurei/Finish.vue')
    },

    // 研修会一覧
    {
        path: '/:category(workshop_shikiho|workshop_shiome|workshop_nikkei|workshop_poker|workshop_irmtg|workshop_online|workshop_event|camp_camp|camp_tour)/',
        name: 'WorkshopList',
        meta: {
            layout: 'default',
            title: '複眼研修会一覧',
            // description: '管理者ページ',
        },
        component: () => import('../views/site/workshop/Index.vue')
    },

    // 研修会詳細
    {
        path: '/:category(workshop_shikiho|workshop_shiome|workshop_nikkei|workshop_poker|workshop_irmtg|workshop_online|workshop_event|camp_camp|camp_tour)/page/:id',
        name: 'WorkshopShow',
        meta: {
            layout: 'default',
            title: '研修会詳細',
            // description: '管理者ページ',
        },
        component: () => import('../views/site/workshop/Show.vue')
    },

    // 研修会申し込み
    {
        path: '/form/:id',
        name: 'WorkshopForm',
        meta: {
            layout: 'default',
            title: '研修会申し込み',
            // description: '管理者ページ',
        },
        component: () => import('../views/site/workshop/Form.vue')
    },

    // 研修会申し込み・確認
    {
        path: '/workshop/confirm/:id',
        name: 'WorkshopConfirm',
        meta: {
            layout: 'default',
            title: '研修会申し込み確認',
            //authenticate_user_join: true
        },
        component: () => import('../views/site/workshop/Confirm.vue')
    },

    // 研修会申し込み・終了
    {
        path: '/:category(workshop_shikiho|workshop_shiome|workshop_nikkei|workshop_poker|workshop_irmtg|workshop_online|workshop_event|camp_camp|camp_tour)/thanks/:id',
        name: 'WorkshopFinish',
        meta: {
            layout: 'default',
            title: '研修会申し込み完了',
            //authenticate_user_join: true
        },
        component: () => import('../views/site/workshop/Finish.vue')
    },

    /*
    // 全講義検索 一覧(旧)
    {
        path: '/archives',
        name: 'Archives',
        meta: {
            layout: 'default',
            title: '全講義検索',
            authenticate_user_join: true
        },
        component: () => import('../views/site/archives/Index.vue')
    },

    // 全講義検索 詳細(旧)
    {
        path: '/:category(shikihobunseki|jijikaisetsu|meigarakoukan|shuho|gepo|turkey-weekly|touring|rule-room|manual|livefaq|library|shikiindex|single|beginning)/page/:id',
        name: 'ArchiveShow',
        meta: {
            layout: 'default',
            title: '全講義検索詳細',
            authenticate_user_join: true
        },
        component: () => import('../views/site/archives/Show.vue')
    },
    */

    // 全講義検索 一覧
    {
        path: '/archives',
        name: 'Archives',
        meta: {
            layout: 'default',
            title: '全講義検索',
            authenticate_user_join: true
        },
        component: () => import('../views/site/archives/Contents.vue')
    },

    // 全講義検索 一覧
    {
        path: '/calendar',
        name: 'Calendar',
        meta: {
            layout: 'default',
            title: '複眼カレンダー'
        },
        component: () => import('../views/site/Calendar.vue')
    },

    // static
    {
        path: '*',
        name: 'Static',
        meta: {
            layout: 'default',
            title: 'Static',
            // description: 'Static'
        },
        component: () => import('../views/Static.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            sessionStorage.setItem('positionY', savedPosition.y)
            return savedPosition
        } else {
            sessionStorage.setItem('positionY', 0)
            return {x: 0, y: 0}
        }
    }
})

export default router
